<template>
    <section class="products">
        <div class="item-container item-container--categories">
            <div class="item item--category" v-for="category in state.categories" v-bind:key="category" v-bind:activeCat="category.id">
                <h3><span>{{category.title}}</span></h3>
                <div class="item-container">
                    <template v-for="product in state.products" v-bind:key="product">
                        <div v-if="product.category_id === category.id" class="item item--product">
                            <h3 v-bind:class="{ active: product.on_homepage === true }">{{product.title}}</h3>
                            <div class="sku">{{ product.default_variant.sku }}</div>
                            <p v-html="product.long_description"></p>
                            <div class="price">{{ product.default_variant.price }} €</div>
                            <br>
                            <strong>Bilder</strong>
                            <div class="images" v-for="image in product.images" v-bind:key="image">
                                <div class="image">{{ image.original_name }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>y    

<script>
import { reactive } from 'vue';
import json from '../json/data2.json';

export default {
  name: 'Products',

  data() {
    return json
  },

  setup() {
      let state = reactive({
          products: json.products,
          categories: json.categories
      });

      return {
          state
      }
  }
}

console.log(json);

</script>

<style lang="scss">
* {
    box-sizing: border-box;
}
.item-container {
    display: flex;
    flex-flow: row wrap;
}
.item {
    flex: 1 1 100%;
    padding: 20px 0;
    position: relative;
    border-bottom: 1px solid #ccc;

    @media screen and (min-width: 415px) {
        flex-basis: 25%;
        border: 1px solid #ccc;
        padding: 10px;
    }

    &.item--product {
        cursor: pointer;

        @media screen and (min-width: 415px) {
            max-width: 25%;
        }

        &:hover {
            background-color: #eee;
        }
        & h3 {
            margin: 0;
        }
    }
    &.item--category {
        flex-basis: 100%;
        padding: 0;
        border: none;
        margin: 0 0 40px 0;

        & h3 span {
            background-color:wheat;
            padding: 4px;
        }
    }
}

ul, li {
    list-style: none;
}

h3.active:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color:yellowgreen;
    border-radius: 50%;
    display: inline-block;
    right: 10px;
    margin-top: 3px;
    position: absolute;
}
</style>