<template>
    <h1>Shop Daten aus Vierwerkstätten Shop - Karin Traxler</h1>
    <products />
    <customers />
</template>

<script>
import Customers from './components/Customers.vue'
import Products from './components/Products.vue'

export default {
  name: 'App',
  components: {
    Customers,
    Products
  }
}
</script>

<style>
#app {
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
section {
  margin: 0 0 40px 0;
}
h2 {
  background-color: whitesmoke;
}
</style>
