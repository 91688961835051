<template>
    <section class="customers">
        <h2>Kunden</h2>
        <div class="item-container">
            <div class="item" v-for="customer in state.customers" v-bind:key="customer">
                <ul>
                    <li>Firma: {{customer.company}}</li>
                    <li>Vorname: {{customer.first_name}}</li>
                    <li>Vorname: {{customer.last_name}}</li>
                    <li>E-Mail: {{customer.email}}</li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import { reactive } from 'vue';
    import json from '../json/data2.json';

    export default {
        name: 'Customers',

        data() {
            return json
        },

        setup() {
            let state = reactive({
                customers: json.customers
            });

            return {
                state
            }
        }
    }
</script>